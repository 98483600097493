import {
  createApp
} from 'vue'
import App from './App.vue'
// import store from './store'
import store from './store'
import router from './router/config-router' // 路由定义
import './permission' // 全局路由守卫 权限控制
import './global.scss' // 全局样式
import 'ant-design-vue/dist/antd.css';
import 'vant/lib/index.css';
import VConsole from 'vconsole';
import { useFacebookPixel } from './utils/facebook-pixel';

// console.log(process.env.NODE_ENV === 'development','process.env.NODE_ENV===');
if (process.env.NODE_ENV === 'development') {
  new VConsole();
}
if (process.env.NODE_ENV !== 'development') {
  console.log = function() {}; // 或者重写为其他功能
}

window.addEventListener('popstate', function () {
  history.pushState(null, null, document.URL)
})
const facebookPixel = useFacebookPixel();
facebookPixel.init('1574435156745472'); // 使用你的Facebook Pixel ID
import {
  List,
  Toast
} from 'vant'
import {
  Message,
  Drawer,
  Input,
  Select,
  Radio,
  Checkbox,
  Form,
  Upload,
  Image,
  Row,
  Col,
  Button,
  Modal,
  Table,
  Progress
} from 'ant-design-vue'

createApp(App)
  .use(Drawer)
  .use(Input)
  .use(Select)
  .use(Radio)
  .use(Checkbox)
  .use(Form)
  .use(Image)
  .use(Upload)
  .use(Row)
  .use(Col)
  .use(Button)
  .use(Modal)
  .use(Table)
  .use(Progress)
  .use(Message)
  .use(router)
  .use(store)
  .use(List)
  .use(Toast)



  .mount('#app')

BigInt.prototype.toJSON = function () {
  const int = Number.parseInt(this.toString());
  return int ?? this.toString();
};